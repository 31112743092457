// src/pages/Events.js
import React from 'react';
import '../styles/Events.css'; // Ensure this CSS file exists for styling
import ImageSlider from './ImageSlider.js';

function Events() {

  const GameNightImages = [
    '/EventPage/GameNight1.jpg',
    '/EventPage/GameNight2.jpg',
    '/EventPage/GameNight3.jpg'
  ];

  const MidAutumnFestivalImages = [
    '/EventPage/MidAutumn1.jpg',
  ];

  const SpringFestivalImages = [
    '/EventPage/SpringFestival1.jpg',
    '/EventPage/SpringFestival2.jpg',
    '/EventPage/SpringFestival3.jpg',
    '/EventPage/SpringFestival5.jpg',
    '/EventPage/SpringFestival7.jpg',
  ];

  
  return (
    <div className="events-container">
      <h1 className="events-title">Review of Past Events</h1>
      <ul className="events-list">
        <li className="event-item">
          <ImageSlider images={GameNightImages} />
          <div>
            <h2 className="event-title">
            <a href="https://mp.weixin.qq.com/s?__biz=MjM5NTI3OTU2Ng==&mid=2652344267&idx=1&sn=ea2c52da0ae36d8d4c9edf5c2b39f0f5&chksm=bd19c86a8a6e417c4e9a207fc768ccfeb1c9f27b8dcfbf1d30779843dc97ac62d35f61857b4a&token=505766654&lang=zh_CN#rd" target="_blank" rel="noopener noreferrer">Game Night</a>
            </h2>
            <p className="event-details">Join us for our game night! Meet new friends and enjoy the activities!</p>
          </div>
        </li>
        <li className="event-item">
          <ImageSlider images={MidAutumnFestivalImages} />
          <div>
            <h2 className="event-title">
            <a href="https://mp.weixin.qq.com/s?__biz=MjM5NTI3OTU2Ng==&mid=2652344073&idx=1&sn=b7aa69f9bae08e2de88ad3f7661102c9&chksm=bd19c8a88a6e41be40ef1e1b8148238e36c0c588b7769ddb39851227d7bf8641665d31e4dc60&token=505766654&lang=zh_CN#rd" target="_blank" rel="noopener noreferrer">Mid-Autumn Festival</a>
            </h2>
            <p className="event-details">Celebrate the Mid-Autumn Festival, enjoy traditional mooncakes, cultural performances, and more!</p>
          </div>
        </li>
        <li className="event-item">
          <ImageSlider images={SpringFestivalImages} />
          <div>  
            <h2 className="event-title">
            <a href="https://mp.weixin.qq.com/s?__biz=MjM5NTI3OTU2Ng==&mid=2652345029&idx=2&sn=fcdeb480f9f9c7e06115279557993f55&chksm=bd19d5648a6e5c72b0d54c8c3a962fdce030159665de6c6148e83ad8eaab8f3b0ad6a44e1633&token=505766654&lang=zh_CN#rd" target="_blank" rel="noopener noreferrer">Spring Festival</a>
            </h2>
          <p className="event-details">Come to our annual Spring Festival night and watch the great shows! </p>
          </div>
        </li>
        {/* Add more events as needed */}
      </ul>
    </div>
  );
}

export default Events;
