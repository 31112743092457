// src/components/Home.js
import React from 'react';
import '../styles/Home.css';

function Home() {
  return (
    <div className="home-container">
      <div className="group-photo">
      <img src={`${process.env.PUBLIC_URL}/001.png`} alt="Group Photo" className="group-photo-image" />
      </div>
      <div className="home-content">
        <h1>Welcome to CSSA UC Davis</h1>
        <p>Here you can find the latest updates and information about our club. Stay tuned for upcoming events and announcements!</p>
        {/* Add more content here as needed */}
      </div>
    </div>
  );
}

export default Home;