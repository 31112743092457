// src/components/Navbar.js
import React from 'react';
import '../styles/Navbar.css';

function Navbar() {
  return (
    <nav className="navbar">
      <div className="navbar-content">
        <img src={`${process.env.PUBLIC_URL}/cssa-logo.png`} alt="CSSA UC Davis Logo" className="navbar-logo" />
        <h1 className="navbar-title">CSSA UC Davis</h1>
      </div>
      <ul className="navbar-links">
        <li><a href="/">Home</a></li>
        <li><a href="/about">About</a></li>
        <li><a href="/events">Events</a></li>
        <li><a href="/contact">Contact</a></li>
      </ul>
    </nav>
  );
}

export default Navbar;
