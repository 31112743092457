// src/pages/Contact.js
import React, { useState } from 'react';
import axios from 'axios';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';

function Contact() {
  const [formData, setFormData] = useState({ name: '', email: '', message: '' });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.post('http://localhost:3001/send-email', formData);
      alert('Email sent successfully!');
    } catch (error) {
      alert('Failed to send email: ' + error.message);
    }
  };

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      minHeight="100vh"
      sx={{ backgroundColor: '#f5f5f5', padding: '2rem' }}
    >
      <Grid container spacing={2} maxWidth="800px" sx={{ boxShadow: 3, borderRadius: 2, backgroundColor: '#fff' }}>
        <Grid item xs={12} textAlign="center" p={3}>
          <Typography variant="h4" color="primary" gutterBottom>
            Contact Us
          </Typography>
          <Typography variant="body1" color="textSecondary">
            We would love to hear from you! Whether you have questions, suggestions, or just want to say hello, feel free to reach out to us.
          </Typography>
        </Grid>

        <Grid item xs={12} p={3}>
          <Typography variant="h6" color="primary">Email</Typography>
          <Typography variant="body1">ucdcssa2016@gmail.com</Typography>

          <Typography variant="h6" color="primary" sx={{ mt: 2 }}>Phone</Typography>
          <Typography variant="body1">(562) 658-9358</Typography>

          <Typography variant="h6" color="primary" sx={{ mt: 2 }}>Address</Typography>
          <Typography variant="body1">
            UC Davis Student Center, Room 123<br />
            1 Shields Avenue<br />
            Davis, CA 95616
          </Typography>
        </Grid>

        <Box component="form" p={3} onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <TextField
                name="name"
                label="Full Name"
                variant="outlined"
                fullWidth
                value={formData.name}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                name="email"
                label="Email"
                type="email"
                variant="outlined"
                fullWidth
                value={formData.email}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                name="message"
                label="What can we help you with?"
                placeholder="Describe your inquiry"
                multiline
                rows={4}
                variant="outlined"
                fullWidth
                value={formData.message}
                onChange={handleChange}
              />
            </Grid>
          </Grid>

          <Box mt={3} textAlign="center">
            <Button type="submit" variant="contained" color="primary" size="large">
              Send Message
            </Button>
          </Box>
        </Box>
      </Grid>
    </Box>
  );
}

export default Contact;
