import React, { useState } from 'react';
import '../styles/Events.css';

function ImageSlider({ images }) {
    const [currentImageIndex, setCurrentImageIndex] = useState(0);

    const goToPrevious = () => {
        const isFirstImage = currentImageIndex === 0;
        const newIndex = isFirstImage ? images.length - 1 : currentImageIndex - 1;
        setCurrentImageIndex(newIndex);
    };

    const goToNext = () => {
        const isLastImage = currentImageIndex === images.length - 1;
        const newIndex = isLastImage ? 0 : currentImageIndex + 1;
        setCurrentImageIndex(newIndex);
    };

    return (
        <div className="image-slider">
            <img src={images[currentImageIndex]} alt={`Slide ${currentImageIndex}`} style={{ width: '500px', height: '400px',  }} />
            <button className="button left " onClick={goToPrevious}>&#8592;</button>
            <button className="button right" onClick={goToNext}>&#8594;</button>
        </div>
    );
}

export default ImageSlider;
