// src/pages/About.js
import React from 'react';
import '../styles/About.css'; // Ensure this CSS file exists for styling

function About() {
  return (
    <div className="about-container">
      <h1 className="about-title">About Us</h1>
      <p className="about-content">
      大家好，我们是加州大学戴维斯分校学生学者联合会(Chinese Students and Scholar Association at UC Davis)💛💙我们是戴维斯最大的华人、非政治、非宗教、非盈利性的学生志愿组织，也是加州大学戴维斯分校唯一受到旧金山大使馆支持的本科学生组织。每年都会有150左右的新生参与到我们cssa，加入我们和新同学们，学长学姐自由交流。同时每年我们也会举办各种各样的活动，例如春晚，K歌大赛，非诚勿扰，非你莫属，篮球赛，线上厨艺大赛等等；活动现场经常有奶茶和精美礼品赠送！欢迎大家加入我们畅享有意思的活动。欢迎关注我们的社交平台获得更多信息！
      </p>
      <div className="about-images">
        <img src={`${process.env.PUBLIC_URL}/AboutPage/AboutUs1.png`} alt="Event 1" className="about-image" />
        <img src={`${process.env.PUBLIC_URL}/AboutPage/AboutUs2.png`} alt="Event 2" className="about-image" />
      </div>
    </div>
  );
}

export default About;
