// src/components/Footer.js
import React from 'react';
import '../styles/Footer.css'; // Ensure this CSS file exists for styling

function Footer() {
  return (
    <footer className="footer">
      <div className="footer-content">
        <p className="footer-text">© 2024 CSSA UC Davis. All rights reserved.</p>
        <ul className="footer-links">
          <li><a href="/about">About Us</a></li>
          <li><a href="/events">Events</a></li>
          <li><a href="/contact">Contact</a></li>
        </ul>
        <p className="footer-text">Follow us on <a href="https://facebook.com/cssa.ucdavis" target="_blank" rel="noopener noreferrer">Facebook</a> and <a href="https://www.instagram.com/cssaatucd/" target="_blank" rel="noopener noreferrer">Instagram</a>.</p>
      </div>
    </footer>
  );
}

export default Footer;
